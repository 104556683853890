import { memo } from "react";
import { Switch } from "@mui/material";
import { switchClasses } from "@mui/material/Switch";
// types
import type { SetRequired } from "type-fest";
import type { SwitchProps } from "@mui/material";

type OmitSwitchProps = "onChange";
type Props = Readonly<SetRequired<Omit<SwitchProps, OmitSwitchProps>, "checked"> & {
	onChange: (checked: boolean) => void;
	vertical?: boolean;
}>;

const VERTICAL_STYLE = {
	width: 14 + 12 * 2,
	height: 34 + 12 * 2,
	[`& .${switchClasses.switchBase}`]: {
		transform: "translateY(20px)", // bottom is off
		[`&.${switchClasses.checked}`]: {
			transform: "translateY(0px)", // top is on
		},
		[`& .${switchClasses.input}`]: {
			top: "-100%",
			left: "0",
			width: "100%",
			height: "300%",
		},
	},
	// TODO: add variants if necessary (props: edge="start"|"end", size="small")
} as const satisfies SwitchProps["sx"];

const CustomSwitch = ({ onChange, vertical = false, ...props }: Props) => (
	<Switch
		{...props}
		onChange={(event) => (onChange(event.target.checked))}
		sx={vertical ? VERTICAL_STYLE : undefined}
	/>
);

export default memo(CustomSwitch);
