import { useState, useMemo } from "react";
import { Link } from "react-router";
import { useTranslation } from "react-i18next";
import {
	Button,
	Divider,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListItemButton,
} from "@mui/material";
import { formatRelative } from "date-fns";
// cmp
import ListItemContainer from "../ListItemContainer";
import UnitDisplay from "./UnitDisplay";
import DrawerDialog from "../DrawerDialog";
import Svg from "../svg";
// contexts
import { useGateway } from "../../context/GatewayContext";
// hooks
import useSend from "../../hooks/useSend";
import useStorage, { StorageKeys } from "../../hooks/useStorage";
// services
import Constants from "../../services/constants";
import ClusterConstants from "../../services/cluster-constants";
import { getCurrencySymbol } from "../../services/currency";
import { roundToPrecision } from "../../services/utils";
import { icons, localeDateFns } from "@local/theme";
// types
import type { EpDevice } from "../../types/device";
import type { DeviceType as DeviceTypeT } from "../../types/device-type";
import type { CmdSendActionCmd } from "../../types/message";
import type { RelativePathPrefix } from "../../types/misc";

type Props = Readonly<{
	epDevice: EpDevice;
	deviceType: DeviceTypeT<"0702">;
	relativePathPrefix: RelativePathPrefix;
	showFull: boolean;
}>;

const D0702 = (props: Props) => {
	const send = useSend();

	const { t } = useTranslation();

	const gateway = useGateway();

	const [replaceData, fallbackReplaceData] = useMemo(() => ([{ GWID: gateway?.id }, { GWID: gateway?.srcGw }] as const), [gateway?.id, gateway?.srcGw]);
	const [electricityUnitPriceValue] = useStorage(StorageKeys.electricityUnitPriceValue, replaceData, fallbackReplaceData);
	const [electricityFeedInUnitPriceValue] = useStorage(StorageKeys.electricityFeedInUnitPriceValue, replaceData, fallbackReplaceData);
	const [electricityUnitPriceCurrency] = useStorage(StorageKeys.electricityUnitPriceCurrency, replaceData, fallbackReplaceData);

	const [loading, setLoading] = useState(false);
	const [showResetDialog, setShowResetDialog] = useState(false);
	const [showProductionResetDialog, setShowProductionResetDialog] = useState(false);
	const [showModeResetDialog, setShowModeResetDialog] = useState(false);

	const cluster = props.epDevice.getClusterByCapAndClusterId(props.deviceType.cap, props.deviceType.clusterId)!;
	const isProductionMode = cluster[ClusterConstants.D0702.Attributes.ProducingOrConsuming] ?? true;

	const handleConsumptionReset = () => {
		setLoading(true);

		const cmd = {
			action: "sendActionCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: ClusterConstants.D0702.CmdIds.ResetZWavePowerMetering,
		} as const satisfies CmdSendActionCmd;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowResetDialog(false);
			}
			setLoading(false);
		});
	};

	const handleProductionReset = () => {
		setLoading(true);

		const cmd = {
			action: "sendActionCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: ClusterConstants.D0702.CmdIds.ResetProduction,
		} as const satisfies CmdSendActionCmd;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowProductionResetDialog(false);
			}
			setLoading(false);
		});
	};

	const handleInvertPower = () => {
		setLoading(true);

		const cmd = {
			action: "sendActionCmd",
			gatewayId: props.epDevice.gwId,
			srcGw: props.epDevice.srcGw,
			deviceId: props.epDevice.id,
			endpoint: props.epDevice.epId,
			caps: props.deviceType.cap,
			clusterId: props.deviceType.clusterId,
			cmdId: ClusterConstants.D0702.CmdIds.InvertPower,
			value: isProductionMode ? ClusterConstants.D0702.CmdPayloads.Consuming : ClusterConstants.D0702.CmdPayloads.Producing,
		} as const satisfies CmdSendActionCmd;
		send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				setShowModeResetDialog(false);
			}
			setLoading(false);
		});
	};

	if (props.showFull) {
		const isResetable = cluster[ClusterConstants.D0702.Attributes.ZWaveResetable] ?? false;

		const lastResetIsoTimestamp = cluster[ClusterConstants.D0702.Attributes.ZwaveLastReset] ?? ""; // ISO-Timestamp
		const _lastResetDate = new Date(lastResetIsoTimestamp);
		const lastResetDate = Number.isNaN(Number(_lastResetDate)) ? null : _lastResetDate;

		const lastProductionResetIsoTimestamp = cluster[ClusterConstants.D0702.Attributes.LastProductionReset] ?? ""; // ISO-Timestamp
		const _lastProductionResetDate = new Date(lastProductionResetIsoTimestamp);
		const lastProductionResetDate = Number.isNaN(Number(_lastProductionResetDate)) ? null : _lastProductionResetDate;

		return (
			<>
				{cluster[ClusterConstants.D0702.Attributes.InvertPowerCapability] &&
					<>
						<ListItem>
							<ListItemIcon><Svg src="smart-widget-state-icons/si_thermostat_mode.svg" /></ListItemIcon>
							<ListItemText primary={t("clusters.D0702.mode")} />
							<ListItemContainer>
								<Button onClick={() => (setShowModeResetDialog(true))}>
									{t(isProductionMode ? "clusters.D0702.production" : "clusters.D0702.consumption")}
								</Button>
							</ListItemContainer>
						</ListItem>
						<Divider />
					</>
				}
				<ListItemButton
					component={Link}
					to={`${props.relativePathPrefix}graph/${props.deviceType.clusterId}/${props.deviceType.attributeId}`}
				>
					<ListItemIcon><Svg src={props.deviceType.entries[ClusterConstants.D0702.Attributes.InstantaneousPowerConsumption].getIcon()} /></ListItemIcon>
					<ListItemText primary={props.deviceType.entries[ClusterConstants.D0702.Attributes.InstantaneousPowerConsumption].getTitle()} />
					<ListItemText primary={props.deviceType.entries[ClusterConstants.D0702.Attributes.InstantaneousPowerConsumption].formatValue(cluster)} />
					<ListItemIcon><icons.ChevronRight /></ListItemIcon>
				</ListItemButton>
				<Divider />
				<ListItem sx={{ justifyContent: "space-between" }}>
					<ListItemIcon><Svg src={props.deviceType.entries[ClusterConstants.D0702.Attributes.OverallPowerConsumption].getIcon()} /></ListItemIcon>
					<ListItemText
						primary={props.deviceType.entries[ClusterConstants.D0702.Attributes.OverallPowerConsumption].getTitle()}
						secondary={isResetable && t("clusters.D0702.lastResetLabel", { DATE: (lastResetDate === null) ? t("clusters.D0702.neverReset") : formatRelative(lastResetDate, new Date(), { locale: localeDateFns }) })}
					/>
					<ListItemContainer>
						<Button
							id="btn-0702-consumption-reset"
							size="large"
							disabled={!isResetable}
							onClick={isResetable ? () => (setShowResetDialog(true)) : undefined}
							sx={{ whiteSpace: "nowrap", textTransform: "initial" }}
						>
							{props.deviceType.entries[ClusterConstants.D0702.Attributes.OverallPowerConsumption].formatValue(cluster, { simple: true })}
						</Button>
					</ListItemContainer>
				</ListItem>
				{Boolean((gateway?.rbac ?? 0) & Constants.Gateway.RBAC.Owner) &&
					<>
						<Divider />
						<ListItem>
							<ListItemIcon><Svg src="smart-widget-state-icons/si_cost.svg" /></ListItemIcon>
							<ListItemText primary={t("clusters.D0702.totalCost")} />
							<ListItemText
								primary={<UnitDisplay
									values={[roundToPrecision((electricityUnitPriceValue ?? 0) * (cluster[ClusterConstants.D0702.Attributes.OverallPowerConsumption] ?? 0) / 100, 0.01)]}
									digits={2}
									unit={` ${getCurrencySymbol(electricityUnitPriceCurrency)}`}
								/>}
							/>
						</ListItem>
					</>
				}
				{(typeof cluster[ClusterConstants.D0702.Attributes.FeedIn] === "number") &&
					<>
						<Divider />
						<ListItemButton
							component={Link}
							to={`${props.relativePathPrefix}graph/${props.deviceType.clusterId}/${ClusterConstants.D0702.Attributes.FeedIn}`}
						>
							<ListItemIcon><Svg src={props.deviceType.entries[ClusterConstants.D0702.Attributes.FeedIn].getIcon()} /></ListItemIcon>
							<ListItemText primary={props.deviceType.entries[ClusterConstants.D0702.Attributes.FeedIn].getTitle()} />
							<ListItemText primary={props.deviceType.entries[ClusterConstants.D0702.Attributes.FeedIn].formatValue(cluster)} />
							<ListItemIcon><icons.ChevronRight /></ListItemIcon>
						</ListItemButton>
						<Divider />
						<ListItem sx={{ justifyContent: "space-between" }}>
							<ListItemIcon><Svg src={props.deviceType.entries[ClusterConstants.D0702.Attributes.OverallPowerProduction].getIcon()} /></ListItemIcon>
							<ListItemText
								primary={props.deviceType.entries[ClusterConstants.D0702.Attributes.OverallPowerProduction].getTitle()}
								secondary={isResetable && t("clusters.D0702.lastResetLabel", { DATE: (lastProductionResetDate === null) ? t("clusters.D0702.neverReset") : formatRelative(lastProductionResetDate, new Date(), { locale: localeDateFns }) })}
							/>
							<ListItemContainer>
								<Button
									id="btn-0702-production-reset"
									size="large"
									disabled={!isResetable}
									onClick={isResetable ? () => (setShowProductionResetDialog(true)) : undefined}
									sx={{ whiteSpace: "nowrap", textTransform: "initial" }}
								>
									{props.deviceType.entries[ClusterConstants.D0702.Attributes.OverallPowerProduction].formatValue(cluster, { simple: true })}
								</Button>
							</ListItemContainer>
						</ListItem>
						<Divider />
						<ListItem>
							<ListItemIcon><Svg src="smart-widget-state-icons/si_cost.svg" /></ListItemIcon>
							<ListItemText primary={t("clusters.D0702.totalValueFeedIn")} />
							<ListItemText
								primary={<UnitDisplay
									values={[roundToPrecision((electricityFeedInUnitPriceValue ?? 0) * (cluster[ClusterConstants.D0702.Attributes.OverallPowerProduction] ?? 0) / 100, 0.01)]}
									digits={2}
									unit={` ${getCurrencySymbol(electricityUnitPriceCurrency)}`}
								/>}
							/>
						</ListItem>
					</>
				}
				<DrawerDialog
					id="dlg-invert-power-mode-invert"
					title={t("clusters.D0702.modeResetDialog.title")}
					open={showModeResetDialog}
					onClose={() => (setShowModeResetDialog(false))}
					dialogContentSx={{ textAlign: "left" }}
					drawerActions={
						<Button
							className="btn-drawer-action-mode-invert"
							variant="contained"
							loading={loading}
							onClick={handleInvertPower}
							sx={{ minWidth: "40%", maxWidth: "320px" }}
						>
							{t("clusters.D0702.modeResetDialog.change")}
						</Button>
					}
					dialogActions={
						<>
							<Button className="btn-dlg-action-cancel" color="inherit" onClick={() => (setShowModeResetDialog(false))}>{t("dialog.cancel")}</Button>
							<Button
								className="btn-dlg-action-mode-invert"
								variant="contained"
								disableElevation={true}
								loading={loading}
								onClick={handleInvertPower}
							>
								{t("clusters.D0702.modeResetDialog.change")}
							</Button>
						</>
					}
				>
					{t("clusters.D0702.modeResetDialog.message")}
					<ul>
						<li>{t("clusters.D0702.modeResetDialog.subMessage1")}</li>
						<li>{t("clusters.D0702.modeResetDialog.subMessage2")}</li>
					</ul>
				</DrawerDialog>
				<DrawerDialog
					id="dlg-power-consumption-reset"
					title={t("clusters.D0702.resetDialog.title")}
					open={showResetDialog}
					onClose={() => (setShowResetDialog(false))}
					dialogContentSx={{ textAlign: "left" }}
					drawerActions={
						<Button
							className="btn-drawer-action-reset"
							variant="contained"
							loading={loading}
							onClick={handleConsumptionReset}
							sx={{ minWidth: "40%", maxWidth: "320px" }}
						>
							{t("dialog.reset")}
						</Button>
					}
					dialogActions={
						<>
							<Button className="btn-dlg-action-cancel" color="inherit" onClick={() => (setShowResetDialog(false))}>{t("dialog.cancel")}</Button>
							<Button
								className="btn-dlg-action-reset"
								variant="contained"
								disableElevation={true}
								loading={loading}
								onClick={handleConsumptionReset}
							>
								{t("dialog.reset")}
							</Button>
						</>
					}
				>
					{t("clusters.D0702.resetDialog.message")}
					<ul>
						<li>{t("clusters.D0702.resetDialog.subMessage1")}</li>
						<li>{t("clusters.D0702.resetDialog.subMessage2")}</li>
					</ul>
				</DrawerDialog>
				<DrawerDialog
					id="dlg-power-production-reset"
					title={t("clusters.D0702.resetProductionDialog.title")}
					open={showProductionResetDialog}
					onClose={() => (setShowProductionResetDialog(false))}
					dialogContentSx={{ textAlign: "left" }}
					drawerActions={
						<Button
							className="btn-drawer-action-reset"
							variant="contained"
							loading={loading}
							onClick={handleProductionReset}
							sx={{ minWidth: "40%", maxWidth: "320px" }}
						>
							{t("dialog.reset")}
						</Button>
					}
					dialogActions={
						<>
							<Button className="btn-dlg-action-cancel" color="inherit" onClick={() => (setShowProductionResetDialog(false))}>{t("dialog.cancel")}</Button>
							<Button
								className="btn-dlg-action-reset"
								variant="contained"
								disableElevation={true}
								loading={loading}
								onClick={handleProductionReset}
							>
								{t("dialog.reset")}
							</Button>
						</>
					}
				>
					{t("clusters.D0702.resetProductionDialog.message")}
					<ul>
						<li>{t("clusters.D0702.resetProductionDialog.subMessage1")}</li>
						<li>{t("clusters.D0702.resetProductionDialog.subMessage2")}</li>
					</ul>
				</DrawerDialog>
			</>
		);
	}

	return null;
};

D0702.defaultProps = {
	showFull: false,
};

export default D0702;
